<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--booking"
    >
      <div
        v-for="booking_detail in item.booking_details"
        :key="booking_detail.id"
        class="flight-details pt-3"
      >
        <div class="row">
          <div class="col-md-3">
            {{ bookingDate(booking_detail.departure_time) }}
          </div>
          <div class="col-md-3">
            {{ bookingTime(booking_detail.departure_time) }}
          </div>
          <div class="col-md-3 flight-lineadjust">
            {{ booking_detail.from.code }}
            <small>({{ booking_detail.from.name }})</small>
            - {{ booking_detail.to.code }}
            <small>({{ booking_detail.to.name }})</small>
          </div>
          <div class="col-md-2">
            {{ bookingTime(booking_detail.arrival_time) }}
            <badge
              v-if="booking_detail.is_live"
              custom-class="badge-md badge-flight"
              type="fl-leg-live"
              :rounded="true"
              size="md"
            >
              Live
            </badge>
            <badge
              v-if="!booking_detail.is_live"
              type="fl-leg-ferry"
              :rounded="true"
              size="md"
              custom-class="badge-md badge-flight"
            >
              Ferry
            </badge>
          </div>
          <template>
            <div v-if="booking_detail.is_live" class="col-md-1">
              <i
                v-permission="[GRANT.EDIT_LEG]"
                class="fas fa-pencil-alt text-primary cursor-pointer"
                @click="
                  toggleLegForm(true, booking_detail, true, item.booked_by)
                "
              ></i>
            </div>
          </template>
        </div>

        <div class="row">
          <div class="col-md-3">Pilot: {{ booking_detail.pilot }}</div>
          <div v-permission="[GRANT.LEG_ADDONS]" class="col-md-9 check-list">
            <base-checkbox
              v-if="booking_detail.is_live"
              input-classes="input-sm"
              container-class="check-item"
              :checked="true"
              :disabled="true"
              @change="onCheckedAddon(booking_detail, 'booked', 'Booked', item)"
            >
              <span class="label-sm">Booked</span>
            </base-checkbox>

            <base-checkbox
              v-if="booking_detail.is_live"
              v-model="booking_detail.details.flight_plan"
              container-class="check-item"
              :disabled="booking_detail.details.flight_plan"
              @change="
                onCheckedAddon(
                  booking_detail,
                  'flight_plan',
                  'Flight Plan',
                  item
                )
              "
            >
              <span class="label-sm">Flight Plan</span>
            </base-checkbox>
            <base-checkbox
              v-if="booking_detail.is_live"
              v-model="booking_detail.details.weather"
              container-class="check-item"
              :disabled="booking_detail.details.weather"
              @change="
                onCheckedAddon(booking_detail, 'weather', 'Weather', item)
              "
            >
              <span class="label-sm">Weather</span>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>

    <leg-form
      :show-leg-modal-form="showLegModalForm"
      :item="itemData"
      :customer="customerData"
      :edite="editeOption"
      @close="toggleLegForm(false)"
    ></leg-form>
  </div>
</template>

<script>
import moment from "moment";
import LegForm from "./LegForm.vue";
import { confirmDialogService } from "@/core/services";
import {
  flightService,
  notificationService,
  firebaseService,
} from "@/core/services";
import { FB_NOTIFICATION_TYPE, GRANT } from "@/core/enums";
import { DateTime } from "@/core/utils";

export default {
  components: {
    LegForm,
  },
  filters: {
    date: function (date) {
      return moment(date).format("Y-MM-DD");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showLegModalForm: false,
      editeOption: false,
      itemData: {
        type: Object,
      },
      customerData: {
        type: Object,
      },
      flight: {
        checkin: {
          booked: true,
          weather: true,
          flight_plan: true,
        },
        checkout: {
          booked: true,
          weather: true,
          flight_plan: true,
        },
      },
      loading: false,
      GRANT: GRANT,
    };
  },
  computed: {
    bookingDate() {
      return (val) => {
        return DateTime.parseUtc(val, "YYYY-MM-DD");
      };
    },
    bookingTime() {
      return (val) => {
        return DateTime.parseUtc(val, "HH:mm", "YYYY-MM-DD HH:mm:ss");
      };
    },
  },
  mounted() {},
  methods: {
    toggleLegForm(val, item, editoption, customerdata) {
      this.showLegModalForm = val;
      this.itemData = item
        ? {
            ...item,
            departure_time: DateTime.parseUtc(item.departure_time),
            arrival_time: DateTime.parseUtc(item.arrival_time),
          }
        : {};
      this.editeOption = editoption;
      this.customerData = customerdata;
    },
    onCheckedAddon(bookingLeg, type, message, booking) {
      confirmDialogService.open(
        `Are you sure you want to add this  ${message} addon  in this flight?`,
        () => {
          flightService
            .changeAddons(bookingLeg.id, bookingLeg.details)
            .then((res) => {
              notificationService.success(res.message);

              if (booking.booked_by.fcm_token) {
                firebaseService.sendNotification(
                  booking.booked_by.fcm_token,
                  {
                    type: FB_NOTIFICATION_TYPE.REGULAR_BOOKING_UPDATED,
                    booking_id: booking.id,
                  },
                  "REGULAR_BOOKING_UPDATED"
                );
              }
            });
        },
        {
          cancelCallback: () => {
            bookingLeg.details[type] = false;
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-lineadjust {
  white-space: normal;
}
</style>
