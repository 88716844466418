<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Flights
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <data-table
                :table-columns="tableColumns"
                :table-data="flightList"
                :border="true"
                row-class-name="row-expanded"
                :loading="loading"
              >
                <template #header-action>
                  <base-button
                    v-permission="[GRANT.ADD_LEG]"
                    size="md"
                    type="primary"
                    @click="toggleLegForm(true)"
                  >
                    <span class="fas fa-plus"></span>
                    Add Leg
                  </base-button>
                </template>

                <template #expandable="{ item }">
                  <flight-details :item="item"></flight-details>
                </template>
              </data-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
    <leg-form
      :show-leg-modal-form="showLegModalForm"
      :edite="editeOption"
      :loading="loading"
      @close="toggleLegForm(false)"
    ></leg-form>
  </div>
</template>

<script>
import { RouteBreadCrumb } from "@/components";
import { flightService } from "@/core/services";
import FlightDetails from "./components/Details.vue";
import { mapGetters } from "vuex";
import LegForm from "./components/LegForm.vue";
import { airportService } from "@/core/services";
import { GRANT } from "@/core/enums";

export default {
  name: "Flights",
  components: {
    RouteBreadCrumb,
    FlightDetails,
    LegForm,
  },

  data() {
    return {
      showLegModalForm: false,
      editeOption: false,

      tableColumns: [
        {
          prop: "booking_details",
          label: "Departure",
          sortable: true,
          component: "date-time",
          propPath: "is_live.departure_time",
          dateLocal: true,
        },
        {
          prop: "booking_details",
          label: "From",
          formatter: (row, _column, _cellValue) => {
            return row.is_live ? row.is_live.from.name : null;
          },
          searchable: true,
          width: "240",
        },
        {
          prop: "booking_details",
          label: "To",
          formatter: (row, _column, _cellValue) => {
            return row.is_live ? row.is_live.to.name : null;
          },
          width: "240",
        },
        {
          prop: "booking_details",
          label: "Arrival",
          component: "date-time",
          propPath: "is_live.arrival_time",
          dateLocal: true,
        },
        {
          prop: "booked_by",
          label: "Customer",
          formatter: (row, _column, _cellValue) => {
            return row.booked_by.full_name;
          },
          searchable: true,
        },
        {
          prop: "pilot",
          label: "Pilot",
          formatter: (row, _column, _cellValue) => {
            return row.is_live ? row.is_live.pilot : null;
          },
        },
        {
          prop: "status",
          label: "Status",
          component: "badge",
          searchable: true,
          align: "center",
          variant: {
            confirmed: "fl-info",
            completed: "fl-success",
            cancelled: "fl-danger",
            pending: "fl-pending",
            pilot_confirmed: "fl-orange",
            "pilot confirmed": "fl-orange",
            "pilot rejected": "fl-brown",
            pilot_rejected: "fl-brown",
          },
        },
      ],
      tableData: [],
      loading: false,
      GRANT: GRANT,
    };
  },
  computed: {
    dtRef() {
      return this.$refs.dt;
    },
    ...mapGetters("flight", ["flightList"]),
  },
  mounted() {
    this.getAllFlight();
    this.getAllAirport();
  },
  methods: {
    toggleLegForm(val) {
      this.showLegModalForm = val;
    },
    getAllAirport() {
      airportService.fetchAll().finally(() => {});
    },
    getAllFlight() {
      this.loading = true;
      flightService.bookings().finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dt-detail-info {
    &__content-sec {
      &--booking {
        .flight-details {
          border-bottom: 1px solid #dbe4e5;
          padding-bottom: 0.7em;
          &:nth-last-of-type(1) {
            /* border-bottom: 0;
            padding-bottom: 0; */
          }
        }
      }
      &--passenger {
        .heading {
          margin-bottom: 0.8em;
          margin-top: 1em;
          font-size: 0.8rem;
          font-weight: 700;
        }
        .pass-detail {
          margin-bottom: 0.5em;
        }
      }
      &--pricing {
        .price-detail {
          margin-bottom: 0.8em;
          .col-md-3 {
            display: flex;
          }
          .badge {
            margin-right: 1em;
          }
          .time-label {
            font-weight: 500;
            width: 40%;
          }
          .time-value {
            font-weight: 500;
          }
        }
      }
      &--customer {
        .address {
          padding-bottom: 0.18em;
          .label {
            font-weight: 600;
            color: #807d7d;
            padding-right: 1em;
          }
        }
      }
    }
  }
}
</style>
