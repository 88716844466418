<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--pricing"
    >
      <div class="row price-detail misc-wrapper">
        <div class="col-md-3 misc-details">
          <span class="misc-details__label">Total Price:</span>
          <span class="misc-details__val">
            {{ item.pricing[0].total_price + item.pricing[0].currency_unit }}
          </span>
        </div>

        <div class="col-md-3 misc-details">
          <span class="misc-details__label">Paid:</span>
          <span class="misc-details__val">
            {{ item.pricing[0].paid + item.pricing[0].currency_unit }}
          </span>
        </div>
        <div class="col-md-3 misc-details">
          <span class="misc-details__label">Outstanding:</span>
          <span class="misc-details__val">
            {{ item.pricing[0].outstanding + item.pricing[0].currency_unit }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-for="item in item.pricing"
      :key="item.id"
      class="dt-detail-info__content-sec dt-detail-info__content-sec--pricing"
    >
      <div class="row price-detail">
        <div class="col-md-3">
          <span class="font-weight-bold time-label">Block Time</span>
          <span class="time-value">
            {{ item.block_time + item.currency_unit }}
          </span>
        </div>
      </div>

      <div class="row price-detail">
        <div class="col-md-3">
          <span class="font-weight-bold time-label">
            {{ item.from }} -
            {{ item.to }}
          </span>

          <badge
            v-if="item.is_live"
            type="fl-leg-live"
            custom-class="badge-md badge-flight"
            :rounded="true"
          >
            {{ item.is_live ? "Live" : "Ferry" }}
          </badge>
          <badge
            v-else
            type="fl-leg-ferry"
            custom-class="badge-md badge-flight"
            :rounded="true"
          >
            {{ item.is_live ? "Live" : "Ferry" }}
          </badge>
          <span class="time-value">{{ item.price + item.currency_unit }}</span>
        </div>
      </div>

      <div class="row price-detail">
        <div class="col-md-3">
          <span class="font-weight-bold time-label">Block Price</span>
          <span class="time-value">
            {{ item.block_price + item.currency_unit }}
          </span>
        </div>
      </div>

      <div class="row price-detail">
        <div class="col-md-3">
          <span class="font-weight-bold time-label">Waiting Time</span>
          <span class="time-value">{{ item.waiting_time }}</span>
        </div>
      </div>

      <div class="row price-detail">
        <div class="col-md-3">
          <span class="font-weight-bold time-label">Airport Fees</span>
          <span class="time-value">
            {{ item.airport_fees + item.currency_unit }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
