<template>
  <div>
    <div v-permission="[GRANT.CHANGE_LEG_STATUS]" class="dt-detail-footer">
      <div class="action-list">
        <div v-if="shownStartedStatus" class="action-list__item">
          <a
            v-permission="[GRANT.CHANGE_LEG_STATUS]"
            href="javascript:void(0)"
            @click="startflight(item)"
          >
            Start Flight
          </a>
        </div>
        <div v-if="shownCancelledStatus" class="action-list__item">
          <a
            v-permission="[GRANT.CHANGE_LEG_STATUS]"
            href="javascript:void(0)"
            @click="cancelflight(item)"
          >
            Cancel Flight
          </a>
        </div>
        <div v-if="shownWaitingStatus" class="action-list__item">
          <a
            v-permission="[GRANT.CHANGE_LEG_STATUS]"
            href="javascript:void(0)"
            @click="startwaiting(item)"
          >
            Start Waiting
          </a>
        </div>
        <!-- v-if="['pending', 'confirmed'].includes(item.status)" -->
        <div v-if="shownClosedStatus" class="action-list__item">
          <a
            v-permission="[GRANT.CHANGE_LEG_STATUS]"
            href="javascript:void(0)"
            @click="closeflight(item)"
          >
            Close Flight
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { confirmDialogService } from "@/core/services";
import { flightService, notificationService } from "@/core/services";
import { BOOKING_STATUS, GRANT } from "@/core/enums";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      GRANT: GRANT,
    };
  },
  computed: {
    shownStartedStatus() {
      return [
        BOOKING_STATUS.WAITING,
        BOOKING_STATUS.CONFIRMED,
        BOOKING_STATUS.PENDING,
      ].indexOf(this.item.status) != -1
        ? true
        : false;
    },
    shownCancelledStatus() {
      return [
        BOOKING_STATUS.COMPLETED,
        BOOKING_STATUS.STARTED,
        BOOKING_STATUS.CANCELLED,
        BOOKING_STATUS.PILOT_CONFIRMED,
        BOOKING_STATUS.PILOT_REJECTED,
      ].indexOf(this.item.status) != -1
        ? false
        : true;
    },
    shownClosedStatus() {
      return [BOOKING_STATUS.STARTED].indexOf(this.item.status) != -1
        ? true
        : false;
    },
    shownWaitingStatus() {
      return this.item.is_live.is_consecutive &&
        this.item.is_live.consecutive_type !== "first" &&
        [BOOKING_STATUS.CONFIRMED].indexOf(this.item.status) != -1
        ? true
        : false;
    },
  },
  methods: {
    startwaiting(item) {
      confirmDialogService.open(
        "Are you want to start waiting this flight?",
        () => {
          flightService
            .changeStatus(item.id, BOOKING_STATUS.WAITING)
            .then((res) => {
              notificationService.success(res.message);
              this.updateStatus(BOOKING_STATUS.WAITING);
            })
            .catch(() => {
              this.showLoading = false;
            });
        }
      );
    },
    startflight(item) {
      confirmDialogService.open("Are you want to start this flight?", () => {
        flightService
          .changeStatus(item.id, BOOKING_STATUS.STARTED)
          .then((res) => {
            notificationService.success(res.message);
            this.updateStatus(BOOKING_STATUS.STARTED);
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    closeflight(item) {
      confirmDialogService.open("Are you want to close this flight?", () => {
        flightService
          .changeStatus(item.id, BOOKING_STATUS.COMPLETED)
          .then((res) => {
            notificationService.success(res.message);
            this.updateStatus(BOOKING_STATUS.COMPLETED);
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    cancelflight(item) {
      console.log(item);

      confirmDialogService.open("Are you want to cancel this flight?", () => {
        flightService
          .changeStatus(item.id, BOOKING_STATUS.CANCELLED)
          .then((res) => {
            notificationService.success(res.message);
            this.updateStatus(BOOKING_STATUS.CANCELLED);
          })
          .catch(() => {
            this.showLoading = false;
          });
        // this.cancelFlight = false;
      });
    },
    updateStatus(status) {
      this.$store.commit("flight/updateBookingStatus", {
        booking_id: this.item.id,
        status: status,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
